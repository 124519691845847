import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import WheelchairPickupOutlinedIcon from "@mui/icons-material/WheelchairPickupOutlined";
// import RecordVoiceOverOutlinedIcon from "@mui/icons-material/RecordVoiceOverOutlined";
import PersonSearchOutlinedIcon from "@mui/icons-material/PersonSearchOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
// import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';

import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
// import { getMe } from "../features/authSlice";
import Avatar from "@mui/material/Avatar";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("");

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { user } = useSelector((state) => state.auth);
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
          display: isMobile && isCollapsed ? "none" : "block",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#d1a362 !important",
        },
        "& .pro-menu-item.active": {
          color: "#b58d53 !important",
        },
        height: isMobile ? "940px" : "full-height",
      }}
    >
      <ProSidebar
        sx={{
          height: isMobile ? "940px" : "full-height",
        }}
        collapsed={isMobile || isCollapsed}
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  sefeo
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  src={user && process.env.REACT_APP_API_BASE_URL + user.url}
                  sx={{
                    width: isMobile ? "40px" : "100px",
                    height: isMobile ? "40px" : "100px",
                  }}
                ></Avatar>
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                ></Typography>
                <Typography
                  variant="h5"
                  color={colors.greenAccent[400]}
                  sx={{
                    fontSize: isMobile ? "10px" : "20px",
                  }}
                >
                  <strong>
                    {user && user.firstName + " " + user.lastName}
                  </strong>
                </Typography>
              </Box>
            </Box>
          )}

          {/* Menu Items */}
          <Box
            paddingLeft={isMobile ? undefined : isCollapsed ? undefined : "10%"}
          >
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {user && user.members[0].id === 1 && (
              <div>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{
                    m: "15px 0 5px 20px",
                    fontSize: isMobile ? "9px" : "15px",
                  }}
                >
                  Master Data
                </Typography>
                <Item
                  title="Member"
                  to="/member"
                  icon={<GroupAddOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Manage Team"
                  to="/team"
                  icon={<Diversity3OutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {/* <Item
                  title="Manage Admin"
                  to="/manageAdmin"
                  icon={<AdminPanelSettingsOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
                <Item
                  title="Role"
                  to="/role"
                  icon={<WheelchairPickupOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Occupation"
                  to="/occupation"
                  icon={<WorkOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: isMobile ? "9px" : "15px",
              }}
            >
              Member Level
            </Typography>

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 3))  && (
              <Item
                title="Beginner Member"
                to="/beginnerParticipant"
                icon={<PersonSearchOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 4)) && (
              <Item
                title="Intermediate Member"
                to="/intermediateParticipant"
                icon={<PersonSearchOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 5)) && (
              <Item
                title="Upper Member"
                to="/upperIntermediateParticipant"
                icon={<PersonSearchOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: isMobile ? "9px" : "15px",
              }}
            >
              Host
            </Typography>

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 3)) && (
              <Item
                title="Beginner Host"
                to="/beginnerHost"
                icon={<ConnectWithoutContactOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 4)) && (
              <Item
                title="Intermediate Host"
                to="/intermediateHost"
                icon={<ConnectWithoutContactOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 5)) && (
              <Item
                title="Upper Host"
                to="/upperIntermediateHost"
                icon={<ConnectWithoutContactOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: isMobile ? "9px" : "15px",
              }}
            >
              Forum Schedule
            </Typography>

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 3)) && (
              <Item
                title="Beginner Schedule"
                to="/beginnerSchedule"
                icon={<CastForEducationOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 4)) && (
              <Item
                title="Intermediate Schedule"
                to="/intermediateSchedule"
                icon={<CastForEducationOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {((user && user.members[0].id === 1) || (user && user.members[0].id === 2) || (user && user.members[0].id === 5)) && (
              <Item
                title="Upper Schedule"
                to="/upperIntermediateSchedule"
                icon={<CastForEducationOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{
                m: "15px 0 5px 20px",
                fontSize: isMobile ? "9px" : "15px",
              }}
            >
              Other
            </Typography>
            <Item
              title="Testimonial"
              to="/testimonial"
              icon={<EditNoteOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Your Profile"
              to="/profile"
              icon={<AccountBoxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {user && user.members[0].id === 1 && (
              <Item
                title="User Activity"
                to="/userActivity"
                icon={<AccessibilityNewIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
