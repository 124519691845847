import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../../api/configApi.js';
import { Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from 'react-redux';
import { getMe } from "../features/authSlice.jsx";
import Avatar from "@mui/material/Avatar";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


const Profile = () => {

  const dispatch = useDispatch();
  const [idProfile, setIdProfile] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [age, setAge] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [gender, setGender] = useState('');
  const [occupationName, setOccupation] = useState('');
  // const [englishProficiencyLevelId, setEnglishProficiencyLevelId] = useState('1');
  const [englishLevel, setEnglishLevel] = useState('');
  const [url, setUrl] = useState('');
  

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseMe = await api.get(`/me`);
        console.log(responseMe.data.id);
        setIdProfile(responseMe.data.id)
        const response = await api.get(`/profile/${responseMe.data.id}`);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setDateOfBirth(response.data.dateOfBirth);
        setAge(response.data.age);
        setPhoneNumber(response.data.phoneNumber);
        setAddress(response.data.address);
        setEmail(response.data.email);
        setCity(response.data.city.city);
        setGender(response.data.gender.gender);
        setOccupation(response.data.occupation.occupationName);
        // setEnglishProficiencyLevelId(response.data.englishProficiencyLevelId);
        setEnglishLevel(response.data.englishProficiencyLevel.level);
        setUrl(response.data.url);
        
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box m="20px">
    <section class="vh-50" style={{backgroundColor: '#f4f5f7', borderRadius: '20px'}}>
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col col-lg-6 mb-4 mb-lg-0">
            <div class="card mb-3" style={{borderRadius: '3rem'}}>
              <div class="row g-0">
                <div class="col-md-4 text-center text-white"
                  style={{background: '#D6B495', borderTopLeftRadius: '3rem', borderBottomLeftRadius: '3rem'}}>
                  <Avatar src={`${url}`} style={{display: 'flex', justifyContent: 'center', width: '100px', height: '100px', marginLeft: '50px', marginTop: '50px', marginBottom: '20px', backgroundColor: 'white'}} round={true} />
                  <h5>{firstName + ' ' + lastName}</h5>
                  <p>{occupationName}</p>
                  <Link to={`/editProfile/${idProfile}`} className="btn btn-warning">Edit <EditOutlinedIcon/></Link>
                </div>
                <div class="col-md-8">
                  <div class="card-body p-4">
                    <h6>Information</h6>
                    <hr class="mt-0 mb-4" />
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Gender</h6>
                        <p class="text-muted">{gender}</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>Date Of Birth</h6>
                        <p class="text-muted">{dateOfBirth}</p>
                      </div>
                    </div>
                    <hr class="mt-0 mb-4" />
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Age</h6>
                        <p class="text-muted">{age} Years</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>English Level</h6>
                        <p class="text-muted">{englishLevel}</p>
                      </div>
                    </div>
                    <hr class="mt-0 mb-4" />
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Email</h6>
                        <p class="text-muted">{email}</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>Whatsapp Number</h6>
                        <p class="text-muted">{phoneNumber}</p>
                      </div>
                    </div>
                    <hr class="mt-0 mb-4" />
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>Address</h6>
                        <p class="text-muted">{address}</p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>City</h6>
                        <p class="text-muted">{city}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-start">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</Box>
  );
};

export default Profile;
