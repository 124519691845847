
import Header from "../../components/Header";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import { Link, useNavigate } from "react-router-dom";
import { Box, TextField, Autocomplete, useMediaQuery } from "@mui/material";
import Swal from "sweetalert2"; 

const AddUpperIntermediateHost = () => {
  const navigate = useNavigate();
  const [memberId, setMemberId] = useState('');
  const [members, setMembers] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers= async () => {
    const responseMembers = await api.get(`/membersByFilterHost`);
    setMembers(responseMembers.data);
  }

  const handleSubmit = async(e) => {
    e.preventDefault();

    const fluentHostData = {
        memberId: memberId,
        roleId: 8
    }

        try {
            await api
             .post(`/host`, fluentHostData)
             .then((response) => {
               console.log(response);
               Swal.fire({  
                  title: 'Success',  
                  icon: 'success',  
                  text: 'New fluent host has been saved.',  
                }); 
               navigate('/fluentHost');
             });
             
         } catch (error) {
             if (error.response) {
                 console.log(error.response);
                 console.log("server responded");
             } else if (error.request) {
               console.log("network error");
             } else {
               console.log(error);
             }
         }    
  
  };

  return (
    <Box m="20px" sx={{
      zoom: isMobile ? '60%' : '100%'
    }}>
    <div style={{ bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <Header title="Add Upper Intermediate Host" subtitle="Add New Upper Intermediate Host From Member" />

          <form className="container" onSubmit={handleSubmit}>
              <div className="text-light">
                  <div className="card-body">
                    <div className="row">
                        <div className="col-lg-12 p-3">
                              <div className="form-group">
                                <Autocomplete
                                    onChange={e => setMemberId(e.target.value)} 
                                    disablePortal
                                    id="member"
                                    getOptionLabel={(members) => `${members.firstName} ${members.lastName}`}
                                    options={members}
                                    noOptionsText="No Available Member"
                                    renderOption={(props, members) => {
                                        return (
                                            
                                        <option {...props} key={members.id} value={members.id}>
                                            {members.firstName + ' ' + members.lastName}
                                        </option>
                                        );
                                    }}
                                    sx={{gridColumn: "span 2"  }}
                                    renderInput={(params) => <TextField {...params} label="Choose a Member" />}
                                />
                                </div>
                            </div>
                        </div>
                  </div>
                  <div className="card-footer">
                      <button type="submit" className="btn btn-success">Make it Host</button> &nbsp; &nbsp;
                      <Link to={'/upperIntermediateHost'} className="btn btn-secondary">Back</Link>
                  </div>
              </div>
          </form>
 
   </div>
</Box>
  );
};

export default AddUpperIntermediateHost;
