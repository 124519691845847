import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import api from "../../api/configApi.js";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Testimonials = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState([]);

  const handleClick = (cellValues) => {
    console.log(cellValues.row);
  };

  useEffect(() => {
    getTestimonial();
  }, []);

  const Publish = async (id) => {
    const testiData = {
      statusPublish: 1,
    };
    await api
      .patch(`/publishTestimonials/${id}`, testiData)
      .then((response) => {
        console.log(response);
      });
  };

  const publishTestimonial = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to publish this testimonial?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f29727",
        cancelButtonColor: "#48ca40",
        confirmButtonText: "Yes, publish it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Publish(id);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Testimonials has been published.",
            timer: 3000,
          }).then((result) => {
            if (result) {
              navigate(0);
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const Delete = async (id) => {
    await api.delete(`/testimonials/${id}`).then((response) => {
      console.log(response);
    });
  };

  const deleteTestimonial = (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this testimonial?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f29727",
        cancelButtonColor: "#48ca40",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Delete(id);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Testimonials has been deleted.",
            timer: 3000,
          }).then((result) => {
            if (result) {
              navigate(0);
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "testimonial",
      headerName: "Testimonial",
      flex: 0.9,
      cellClassName: "name-column--cell",
    },
    {
      field: "statusPublish",
      headerName: "Status Publish",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "Publish",
      Header: "Publish",
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              publishTestimonial(id);
            }}
          >
            <PublishedWithChangesOutlinedIcon />
          </Button>
        );
      },
    },
    {
      field: "Edit",
      Header: "Edit",
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link to={`/editTestimonial/${id}`}>
            <Button
              variant="contained"
              color="warning"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: "Delete",
      Header: "Delete",
      flex: 0.5,
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              deleteTestimonial(id);
              // console.log(id);
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  const getTestimonial = async () => {
    const responseMe = await api.get(`/me`);

    if (responseMe.data.members[0].id === 1) {
      const response = await api.get(`/testimonials/`);
      setTestimonial(response.data);
    } else {
      const response = await api.get(
        `/testimonialByMemberId/${responseMe.data.id}`
      );
      setTestimonial(response.data);
    }
  };

  return (
    <Box m="20px">
      <Header title="Testimonial" subtitle="List of Testimonial" />
      {testimonial.length === 0 && (
        <Button variant="contained" color="success" href="./addTestimonial">
          <AddIcon />
          Add Data
        </Button>
      )}

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={testimonial}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Testimonials;
