import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import api from '../../api/configApi.js';
import { Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header.jsx";
import Swal from "sweetalert2"; 


const AddMember = () => {
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [cityId, setCityId] = useState('1');
  const [genderId, setGenderId] = useState('1');
  const [occupationId, setOccupationId] = useState('1');
  const [englishProficiencyLevelId, setEnglishProficiencyLevelId] = useState('1');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const [cities, setCity] = useState([]);
  const [occupations, setOccupation] = useState([]);


    var dob = new Date(dateOfBirth);
  //   console.log(dob);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();
    
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff); 
    
    //extract year from date    
    var year = age_dt.getUTCFullYear();
    
    //now calculate the age of the user
    var age = Math.abs(year - 1970);

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    getOccupation();
  }, []);


  const getCity = async () => {
    const responseCity = await api.get(`/signupcity`);
    setCity(responseCity.data);
  }


  const getOccupation = async () => {
    const responseOccupation = await api.get(`/signupoccupation`);
    setOccupation(responseOccupation.data);
  }

  const IsValidate = () => {
    let isproceed = true;
    if (password !== confPassword){
      isproceed = false;
      Swal.fire({  
        title: 'Failed!',  
        icon: 'error',  
        text: 'Password and Confirm Password Do Not Match!',  
      }); 
    }
    return isproceed;
}


  

const handleSubmit = async(e) => {
  e.preventDefault();

  let roleId;

  if (englishProficiencyLevelId === '1' || englishProficiencyLevelId === 1) {
      roleId = '3'
    } else if (englishProficiencyLevelId === '2' || englishProficiencyLevelId === 2) {
      roleId = '4'
    } else if (englishProficiencyLevelId === '3' || englishProficiencyLevelId === 3) {
      roleId = '5'
    }

  const responseValidateEmail = await api.get(`/membersEmail/${email}`);

  if(responseValidateEmail.data === null){
    const memberData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        dateOfBirth: dateOfBirth,
        age: age,
        phoneNumber: phoneNumber,
        cityId: cityId,
        address: address,
        genderId: genderId,
        password: password,
        confPassword: confPassword,
        occupationId: occupationId,
        englishProficiencyLevelId: englishProficiencyLevelId,
        status: 1,
        roleId: roleId
    }
        if(IsValidate()){
            try {
                await api
                .post(`/registerUser`, memberData)
                .then((response) => {
                    console.log(response);
                    Swal.fire({  
                        title: 'Success',  
                        icon: 'success',  
                        text: 'New member has been saved.',  
                    }); 
                    navigate('/member');
                });
                
            } catch (error) {
                if (error.response) {
                    console.log(error.response);
                    console.log("server responded");
                } else if (error.request) {
                    console.log("network error");
                } else {
                    console.log(error);
                }
            }
        }   
    } else {
        Swal.fire({  
            title: 'Failed!',  
            icon: 'error',  
            text: 'Email already registered!',  
        }); 
    }     

};

  return (
    <Box m="20px">
    <div style={{ bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <Header title="Create Member" subtitle="Create a New Sefeo Member" />

          <form className="container" onSubmit={handleSubmit}>
              <div className="text-light">
                  <div className="card-body">

                      <div className="row">
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>First Name <span className="errmsg">*</span></label>
                                  <input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Last Name <span className="errmsg">*</span></label>
                                  <input value={lastName} onChange={e => setLastName(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Date Of Birth <span className="errmsg">*</span></label>
                                  <input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Email <span className="errmsg">*</span></label>
                                  <input value={email} onChange={e => setEmail(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Whatsapp Number <span className="errmsg"></span></label>
                                  <input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Your City <span className="errmsg">*</span></label>
                                  <select value={cityId} onChange={e => setCityId(e.target.value)} className="form-control">
                                      <option disabled>Choose Your City</option>
                                  {cities.map((city, index) => (
                                      <option key={city.id} value={city.id}>{city.city}</option>
                                  ))
                                  }
                                  </select>
                              </div>
                          </div>
                          <div className="col-lg-12 p-3">
                              <div className="form-group">
                                  <label>Address</label>
                                  <textarea value={address} onChange={e => setAddress(e.target.value)} className="form-control"></textarea>
                              </div>
                          </div>

                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Password <span className="errmsg">*</span></label>
                                  <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Confirm Password <span className="errmsg">*</span></label>
                                  <input value={confPassword} onChange={e => setConfPassword(e.target.value)} type="password" className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Your Occupation <span className="errmsg">*</span></label>
                                  <select value={occupationId} onChange={e => setOccupationId(e.target.value)} className="form-control">
                                  {occupations.map((occupation, index) => (
                                      <option key={occupation.id} value={occupation.id}>{occupation.occupationName}</option>
                                  ))
                                  }
                                  </select>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>English Proficiency Level <span className="errmsg">*</span></label>
                                  <select value={englishProficiencyLevelId} onChange={e => setEnglishProficiencyLevelId(e.target.value)} className="form-control">
                                      <option key="1" value="1">Beginner</option>
                                      <option key="2" value="2">Intermediate</option>
                                      <option key="3" value="3">Fluent</option>
                                  </select>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group m-3">
                                  <label>Gender</label>
                                  <br></br>
                                  <input type="radio" checked={genderId === '1'} onChange={e => setGenderId(e.target.value)} name="gender" value="1" className="app-check"></input>
                                  &nbsp;<label>Male</label>&nbsp; &nbsp;&nbsp;
                                  <input type="radio" checked={genderId === '2'} onChange={e => setGenderId(e.target.value)} name="gender" value="2" className="app-check"></input>
                                  &nbsp;<label>Female</label>
                              </div>
                          </div>

                      </div>

                  </div>
                  <div className="card-footer">
                      <button type="submit" className="btn btn-success">Register</button> &nbsp; &nbsp;
                      <Link to={'/member'} className="btn btn-warning">Back</Link>
                  </div>
              </div>
          </form>
 
   </div>
</Box>
  );
};

export default AddMember;
