import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import { Link, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { Box} from "@mui/material";
import Header from "../../components/Header.jsx";
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "../features/authSlice.jsx";
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery } from "@mui/material";
import {

  SefeoTeamWrapper,

} from './customElements.jsx';

const Team = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(user && user.members[0].id !== 1){
      navigate("/");
    }
  }, [user, navigate]);

  const handleClick = (cellValues) => {
  };


  const [team, setTeam] = useState([]);

  useEffect(() => {
    getTeam();
  }, []);

  const getTeam = async () => {
    const response = await api.get(`/teams`);
    setTeam(response.data);
  };
  
  return (
    <Box m="20px" sx={{
      zoom: isMobile ? '60%' : '100%'
    }}>
      <Header title="Team" subtitle="Managing the Sefeo Team" />

      <SefeoTeamWrapper>
      {team.map((team) => (
        <ImageListItem key={team.id} sx={{ width: 150, height: 75 }}>
          <img

            src={`${process.env.REACT_APP_API_BASE_URL + team.url}?w=248&fit=crop&auto=format`}
            srcSet={`${process.env.REACT_APP_API_BASE_URL + team.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={team.position}
            loading="lazy"
          />
          <ImageListItemBar
            title={team.member.firstName + ' ' + team.member.lastName}
            subtitle={<h6>{team.position}</h6>}
            position="below"
          />

          <Link to={`/editTeam/${team.id}`}>
            <Button
              variant="contained"
              color="warning"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              <EditIcon />
            </Button>
          </Link>
        </ImageListItem>
      ))}
      </SefeoTeamWrapper>
    </Box>
  );
};

export default Team;
