// import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";

import Team from "./scenes/team";
import EditTeam from "./scenes/team/editTeam";

import Invoices from "./scenes/invoices";

import AddMember from "./scenes/member/addMember";
import Member from "./scenes/member";
import EditMember from "./scenes/member/editMember";

import BeginnerParticipant from "./scenes/beginnerParticipant";
import IntermadiateParticipant from "./scenes/intermediateParticipant";
import UpperIntermediateParticipant from "./scenes/upperIntermediateParticipant";

import BeginnerHost from "./scenes/beginnerHost";
import AddBeginnerHost from "./scenes/beginnerHost/addBeginnerHost";

import IntermadiateHost from "./scenes/intermediateHost";
import AddIntermediateHost from "./scenes/intermediateHost/addIntermediateHost";

import UpperIntermediateHost from "./scenes/upperIntermediateHost";
import AddUpperIntermediateHost from "./scenes/upperIntermediateHost/addUpperIntermediateHost";

import Role from "./scenes/role";
import AddRole from "./scenes/role/addRole";
import EditRole from "./scenes/role/editRole";

import Occupation from "./scenes/occupation";
import AddOccupation from "./scenes/occupation/addOccupation";
import EditOccupation from "./scenes/occupation/editOccupation";

import Testimonial from "./scenes/testimonial";
import AddTestimonial from "./scenes/testimonial/addTestimonial.jsx";
import EditTestimonial from "./scenes/testimonial/editTestimonial.jsx";

import Profile from "./scenes/profile";
import EditProfile from "./scenes/profile/editProfile";

import BeginnerSchedule from "./scenes/beginnerSchedule";
import AddBeginnerSchedule from "./scenes/beginnerSchedule/addBeginnerSchedule";

import IntermediateSchedule from "./scenes/intermediateSchedule";
import AddIntermediateSchedule from "./scenes/intermediateSchedule/addIntermediateSchedule";

import UpperIntermediateSchedule from "./scenes/upperIntermediateSchedule";
import AddUpperIntermediateSchedule from "./scenes/upperIntermediateSchedule/addUpperIntermediateSchedule";

import UserActivity from "./scenes/userActivity";

import Calendar from "./scenes/calendar";
import Form from "./scenes/form";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";

import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "./scenes/features/authSlice";


function App() {
  const [theme, colorMode] = useMode();
  // const [isSidebar, setIsSidebar] = useState(true);

  const dispatch = useDispatch();
  const { isError } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(isError){
      window.location.replace(`${process.env.REACT_APP_PUBLIC_URL}/signin`);
    }
}, [isError]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar />
          <main className="content">
            <Topbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />
              <Route path="/editTeam/:id" element={<EditTeam />} />

              <Route path="/addMember" element={<AddMember />} />
              <Route path="/member" element={<Member />} />
              <Route path="/editMember/:id" element={<EditMember />} />

              {/* Role */}
              <Route path="/role" element={<Role />} />
              <Route path="/addRole" element={<AddRole />} />
              <Route path="/editRole/:id" element={<EditRole />} />

             {/* Occupation */}
             <Route path="/occupation" element={<Occupation />} />
            <Route path="/addOccupation" element={<AddOccupation />} />
             <Route path="/editOccupation/:id" element={<EditOccupation />} />

              {/* Participant */}

              <Route path="/beginnerParticipant" element={<BeginnerParticipant />} />
              <Route path="/intermediateParticipant" element={<IntermadiateParticipant />} />
              <Route path="/upperIntermediateParticipant" element={<UpperIntermediateParticipant />} />

              {/* Host */}
              <Route path="/beginnerHost" element={<BeginnerHost />} />
              <Route path="/addBeginnerHost" element={<AddBeginnerHost />} />

              <Route path="/intermediateHost" element={<IntermadiateHost />} />
              <Route path="/addIntermediateHost" element={<AddIntermediateHost />} />

              <Route path="/upperIntermediateHost" element={<UpperIntermediateHost />} />
              <Route path="/addUpperIntermediateHost" element={<AddUpperIntermediateHost />} />

              {/* Testimonial */}
              <Route path="/testimonial" element={<Testimonial />} />
              <Route path="/addTestimonial" element={<AddTestimonial />} />
              <Route path="/editTestimonial/:id" element={<EditTestimonial />} />

              {/* Profile */}
              <Route path="/profile" element={<Profile />} />
              <Route path="/editProfile/:id" element={<EditProfile />} />

              <Route path="/beginnerSchedule" element={<BeginnerSchedule />} />
              <Route path="/addBeginnerSchedule" element={<AddBeginnerSchedule />} />

              <Route path="/intermediateSchedule" element={<IntermediateSchedule />} />
              <Route path="/addIntermediateSchedule" element={<AddIntermediateSchedule />} />

              <Route path="/upperIntermediateSchedule" element={<UpperIntermediateSchedule />} />
              <Route path="/addUpperIntermediateSchedule" element={<AddUpperIntermediateSchedule />} />

              <Route path="/userActivity" element={<UserActivity />} />

              <Route path="/invoices" element={<Invoices />} />
              <Route path="/form" element={<Form />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/geography" element={<Geography />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
