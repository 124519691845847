import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams} from "react-router-dom";
import api from '../../api/configApi.js';
import { Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header.jsx";
import Swal from "sweetalert2"; 
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "../features/authSlice.jsx";


const EditProfile = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [cityId, setCityId] = useState('');
  const [genderId, setGenderId] = useState('');
  const [occupationId, setOccupationId] = useState('1');
  const [englishProficiencyLevelId, setEnglishProficiencyLevelId] = useState('1');
  const [englishLevel, setEnglishLevel] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');

  const [file, setFile] = useState('');
  const [preview, setPreview] = useState('');
  
  const loadImage = (e) => {
    const profileImage = e.target.files[0];
    setFile(profileImage);
    setPreview(URL.createObjectURL(profileImage));
  };

  const [roleId, setRoleId] = useState('');

  const [cities, setCity] = useState([]);
  const [occupations, setOccupation] = useState([]);
  
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);


    var dob = new Date(dateOfBirth);

    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();
    
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff); 
    
    //extract year from date    
    var year = age_dt.getUTCFullYear();
    
    //now calculate the age of the user
    var age = Math.abs(year - 1970);

  useEffect(() => {
    getCity();
  }, []);

  useEffect(() => {
    getOccupation();
  }, []);

  useEffect(()=>{
    const getProfileById = async () => {
      const response = await api.get(`/profile/${id}`);
      setFirstName(response.data.firstName);
      setLastName(response.data.lastName);
      setDateOfBirth(response.data.dateOfBirth);
      setPhoneNumber(response.data.phoneNumber);
      setAddress(response.data.address);
      setEmail(response.data.email);
      setCityId(response.data.cityId);
      setGenderId(response.data.genderId);
      setOccupationId(response.data.occupationId);
      setEnglishProficiencyLevelId(response.data.englishProficiencyLevelId);
      setEnglishLevel(response.data.englishProficiencyLevel.level);
    };
    
    getProfileById();
  }, [id]);


  const getCity = async () => {
    const responseCity = await api.get(`/signupcity`);
    setCity(responseCity.data);
  }


  const getOccupation = async () => {
    const responseOccupation = await api.get(`/signupoccupation`);
    setOccupation(responseOccupation.data);
  }

  const IsValidate = () => {
    let isproceed = true;
    if (password !== confPassword){
      isproceed = false;
      Swal.fire({  
        title: 'Failed!',  
        icon: 'error',  
        text: 'Password and Confirm Password Do Not Match!',  
      }); 
    }
    return isproceed;
}

  
//   console.log(user.id);
const handleSubmit = async(e) => {
  e.preventDefault();
  if (englishProficiencyLevelId === '1') {
    setRoleId('3');
  } else if (englishProficiencyLevelId === '2') {
    setRoleId('4');
  } else if (englishProficiencyLevelId === '3') {
    setRoleId('5');
  }

  if(IsValidate()){
    const formData = new FormData();
    formData.append("file", file);
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("dateOfBirth", dateOfBirth);
    formData.append("age", age);
    formData.append("phoneNumber", phoneNumber);
    formData.append("cityId", cityId);
    formData.append("address", address);
    formData.append("genderId", genderId);
    formData.append("password", password);
    formData.append("confPassword", confPassword);
    formData.append("occupationId", occupationId);
    formData.append("englishProficiencyLevelId", englishProficiencyLevelId);
    formData.append("status", 1);
    formData.append("roleId", roleId);
    formData.append("lastAuthorUpdateId", user.id);
  
      try {
          await api
           .patch(`/profile/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
           })
           .then((response) => {
             console.log(response);
             Swal.fire({  
                title: 'Success',  
                icon: 'success',  
                text: 'Profile has been updated.',  
              }); 
             navigate('/profile');
           });
           
       } catch (error) {
           if (error.response) {
               console.log(error.response);
               console.log("server responded");
           } else if (error.request) {
             console.log("network error");
           } else {
             console.log(error);
           }
       }
    }   

};
  return (
    <Box m="20px">
    <div style={{ bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <Header title="UPDATE PROFILE" subtitle="Update Profile a Sefeo Member" />
      <div className="col-lg-6 p-3">
          <div className="form-group">
            <h3>{email}</h3>
          </div>
          <div className="form-group">
            <h6>{englishLevel} Level </h6>
          </div>
      </div>
          <form className="container" onSubmit={handleSubmit}>
              <div className="text-light">
                  <div className="card-body">

                  <div className="row">
                        <div className="col-lg-4 p-3">
                            <div className="form-group">
                                <label htmlFor="formFile" className="form-label">Change Profile Picture</label>
                                <input className="form-control" type="file" id="formFile" onChange={loadImage} />
                            </div>
                        </div>
                    </div>
                    {preview ? (
                    <div className="row">
                      <div className="col-lg-6 p-3">
                        <div className="form-group">
                          <figure className="figure">
                            <img src={preview} className="figure-img img-fluid w-25 h-25 rounded" alt={preview} />
                          </figure>
                      </div>
                    </div>
                  </div>
                  ) : ("")}

                      <div className="row">
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>First Name <span className="errmsg">*</span></label>
                                  <input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Last Name <span className="errmsg">*</span></label>
                                  <input value={lastName} onChange={e => setLastName(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Date Of Birth <span className="errmsg">*</span></label>
                                  <input type="date" value={dateOfBirth} onChange={e => setDateOfBirth(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Whatsapp Number <span className="errmsg"></span></label>
                                  <input value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} className="form-control"></input>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Your City <span className="errmsg">*</span></label>
                                  <select value={cityId} onChange={e => setCityId(e.target.value)} className="form-control">
                                      <option disabled>Choose Your City</option>
                                  {cities.map((city, index) => (
                                      <option key={city.id} value={city.id}>{city.city}</option>
                                  ))
                                  }
                                  </select>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Your Occupation <span className="errmsg">*</span></label>
                                  <select value={occupationId} onChange={e => setOccupationId(e.target.value)} className="form-control">
                                  {occupations.map((occupation, index) => (
                                      <option key={occupation.id} value={occupation.id}>{occupation.occupationName}</option>
                                  ))
                                  }
                                  </select>
                              </div>
                          </div>
                          <div className="col-lg-12 p-3">
                              <div className="form-group">
                                  <label>Address</label>
                                  <textarea value={address} onChange={e => setAddress(e.target.value)} className="form-control"></textarea>
                              </div>
                          </div>

                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Password </label>
                                  <input value={password} onChange={e => setPassword(e.target.value)} type="password" className="form-control"></input>
                                  <span className="errmsg">*Fill the new password if you want to update your password</span>
                              </div>
                          </div>
                          <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>Confirm Password</label>
                                  <input value={confPassword} onChange={e => setConfPassword(e.target.value)} type="password" className="form-control"></input>
                                  <span className="errmsg">*Fill the new confirm password if you want to update your password</span>
                              </div>
                          </div>
                          {/* <div className="col-lg-6 p-3">
                              <div className="form-group">
                                  <label>English Proficiency Level <span className="errmsg">*</span></label>
                                  <select value={englishProficiencyLevelId} onChange={e => setEnglishProficiencyLevelId(e.target.value)} className="form-control">
                                      <option key="1" value="1">Beginner</option>
                                      <option key="2" value="2">Intermediate</option>
                                      <option key="3" value="3">Fluent</option>
                                  </select>
                              </div>
                          </div> */}
                          <div className="col-lg-6 p-3">
                              <div className="form-group m-3">
                                  <label>Gender</label>
                                  <br></br>
                                  <input type="radio" checked={genderId === '1' || genderId === 1 } onChange={e => setGenderId(e.target.value)} name="gender" value="1" className="app-check"></input>
                                  &nbsp;<label>Male</label>&nbsp; &nbsp;&nbsp;
                                  <input type="radio" checked={genderId === '2' || genderId === 2 } onChange={e => setGenderId(e.target.value)} name="gender" value="2" className="app-check"></input>
                                  &nbsp;<label>Female</label>
                              </div>
                          </div>

                      </div>

                  </div>
                  <div className="card-footer">
                      <button type="submit" className="btn btn-success">Update Profile</button> &nbsp; &nbsp;
                      <Link to={'/profile'} className="btn btn-warning">Back</Link>
                  </div>
              </div>
          </form>
 
   </div>
</Box>
  );
};

export default EditProfile;
