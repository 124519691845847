import { Box, } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "../features/authSlice";

const Member = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(user && user.members[0].id !== 1){
      navigate("/");
    }
  }, [user, navigate]);


  const handleClick = (event, cellValues) => {
    // console.log(cellValues.row);
  };

  const [members, setMember] = useState([]);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = async () => {
    const response = await api.get(`/members`);
    setMember(response.data);
  };


  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.5,
      cellClassName: "id",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "gender",
      valueGetter: (members) => members.row.gender.gender,
      headerName: "Gender",
      flex: 0.2,
      cellClassName: "name-column--cell",
    },
    {
        field: "dateOfBirth",
        headerName: "Date Of Birth",
        flex: 0.4,
        cellClassName: "name-column--cell",
      },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      flex: 0.2,
      cellClassName: "name-column--cell",
    },
    {
      field: "phoneNumber",
      headerName: "WA Number",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "occupation",
      valueGetter: (members) => members.row.occupation.occupationName,
      headerName: "Occupation",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "city",
      valueGetter: (members) => members.row.city.city,
      headerName: "Hometown",
      flex: 0.7,
      cellClassName: "name-column--cell",
    },
    {
      field: "level",
      valueGetter: (members) => members.row.englishProficiencyLevel.level,
      headerName: "English Level",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
        field: "Action",
        Header: "Action",
        flex: 0.4,
        renderCell: ({ row: { id } }) => {
          return (
            <Link to={`/editMember/${id}`}>
              <Button
                variant="contained"
                color="warning"
                onClick={(event) => {
                  handleClick(event);
                }}
              >
                <EditIcon />
              </Button>
            </Link>
          );
        }
    }
  ];

  return (
    <Box m="20px">
      <Header
        title="Sefeo Members"
        subtitle="List of Sefeo Members"
      />
      <Button variant="contained" color="success" href="./addMember"><AddIcon />Add Data</Button>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={members}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Member;
