import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
// import Swal from "sweetalert2"; 

const BeginnerParticipant = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const navigate = useNavigate();
  const handleClick = (cellValues) => {
    console.log(cellValues.row);
  };

  const { user } = useSelector((state) => state.auth);

  const [beginnerParticipant, setBeginnerParticipant] = useState([]);

  useEffect(() => {
    getBeginnerParticipant();
  }, []);

  const getBeginnerParticipant = async () => {
    const response = await api.get(`/memberByRoleId/3`);
    setBeginnerParticipant(response.data);
  };

  const UpdateIntermediate = async (id) => {

     await api
    .delete(`/participantLevel/${id}`, {
      roleId: 3
    })
    .then(response => {
      console.log(response);
      });

    const englishLevelData = {
      roleId: 4,
      memberId: id
    };

    await api
          .post(`/participantLevel/`, englishLevelData)
          .then(response => {
            console.log(response);
            });
            console.log(beginnerParticipant);
            console.log(englishLevelData);
  }

  // const updateEnglishLevel = (id) => {
  //   try {
  //     Swal.fire({
  //       title: 'Are you sure?',
  //       text: "Do you want to update this participant level?",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#f29727',
  //       cancelButtonColor: '#48ca40',
  //       confirmButtonText: 'Yes, update it!'
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
            
  //           UpdateIntermediate(id);
  //           Swal.fire({  
  //             title: 'Success',  
  //             icon: 'success',  
  //             text: 'Participant english level has been updated.',  
  //             timer: 3000
  //           })
  //           .then((result) => {
  //             if (result) {
  //             navigate(0);
  //             }
  //           })
  //         }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const columns = [ 
    { field: "id", headerName: "ID", flex: 0.3 },
    {
        field: "firstName",
        headerName: "First Name",
        flex: 0.4,
        cellClassName: "name-column--cell",
    },
    {
        field: "lastName",
        headerName: "Last Name",
        flex: 0.4,
        cellClassName: "name-column--cell",
    },
    {
        field: "gender",
        valueGetter: (beginnerParticipant) => beginnerParticipant.row.gender.gender,
        headerName: "Gender",
        flex: 0.3,
        cellClassName: "name-column--cell",
    },
    {
        field: "age",
        headerName: "Age",
        flex: 0.2,
        cellClassName: "name-column--cell",
    },
    {
        field: "occupationName",
        valueGetter: (beginnerParticipant) => beginnerParticipant.row.occupation.occupationName,
        headerName: "Occupation",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
      field: "city",
      valueGetter: (beginnerParticipant) => beginnerParticipant.row.city.city,
      headerName: "Hometown",
      flex: 0.4,
      cellClassName: "name-column--cell",
  },
  {
      field: "role",
      valueGetter: (beginnerParticipant) => {
        let result = [];
        if (beginnerParticipant.row.members != null) {
            beginnerParticipant.row.members.map((members, index) => (
                result = members.role
            ))

        }
        return result;
      },
      headerName: "Role",
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "Move Intermediate",
      Header: "Move Intermediate",
      flex: 0.35,
      renderCell: ({ row: { id } }) => {
        return (
          user && user.members[0].id === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
            
                UpdateIntermediate(id);
              }}
            >
              Intermediate
            </Button>
          )
        );
      }
  },
  {
    field: "Move Fluent",
    Header: "Move Fluent",
    flex: 0.4,
    renderCell: ({ row: {id} }) => {
      return (
        user && user.members[0].id === 1 && (
        <Link to={`/editBeginnerParticipant/${id}`}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              handleClick(event);
            }}
          >
            Fluent
          </Button>
        </Link>
        )
      );
    }
  }
  ];

  return (
    <Box m="20px">
      <Header
        title="Beginner Participant"
        subtitle="List of Beginner Participant"
      />

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={beginnerParticipant}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default BeginnerParticipant;
