
import Header from "../../components/Header";
import React, {useState} from "react";
import api from '../../api/configApi.js';
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Swal from "sweetalert2"; 
import { useSelector } from 'react-redux';

const AddTestimonial = () => {
  const navigate = useNavigate();
  const [testimonial, setTestimonial] = useState('');
  const { user } = useSelector((state) => state.auth);


  const handleSubmit = async(e) => {
    e.preventDefault();

    const testiData = {
        testimonial: testimonial,
        memberId: user.id
    }
    console.log(testiData);
        try {
            await api
             .post(`/testimonials`, testiData)
             .then((response) => {
               console.log(response);
               Swal.fire({  
                  title: 'Success',  
                  icon: 'success',  
                  text: 'New testimonial has been saved.',  
                }); 
               navigate('/testimonial');
             });
             
         } catch (error) {
             if (error.response) {
                 console.log(error.response);
                 console.log("server responded");
             } else if (error.request) {
               console.log("network error");
             } else {
               console.log(error);
             }
         }    
  
  };

  return (
    <Box m="20px">
    <div style={{ bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <Header title="CREATE TESTIMONIAL" subtitle="Create a New Testimonial" />

          <form className="container" onSubmit={handleSubmit}>
              <div className="text-light">
                  <div className="card-body">

                      <div className="row">
                          <div className="col-lg-12 p-3">
                              <div className="form-group">
                                  <label>Testimonial</label>
                                  <textarea value={testimonial} onChange={e => setTestimonial(e.target.value)} className="form-control" placeholder="Write your testimonial.."></textarea>
                              </div>
                          </div>

                      </div>

                  </div>
                  <div className="card-footer">
                      <button type="submit" className="btn btn-success">Create Testimonial</button> &nbsp; &nbsp;
                      <Link to={'/testimonial'} className="btn btn-secondary">Back</Link>
                  </div>
              </div>
          </form>
 
   </div>
</Box>
  );
};

export default AddTestimonial;
