import { Box, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

const UpperIntermediateParticipant = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleClick = (cellValues) => {
    console.log(cellValues.row);
  };

  const { user } = useSelector((state) => state.auth);

  const [fluentParticipant, setFluentParticipant] = useState([]);

  useEffect(() => {
    getFluentParticipant();
  }, []);

  const getFluentParticipant = async () => {
    const response = await api.get(`/memberByRoleId/5`);
    setFluentParticipant(response.data);
  };


  const columns = [ 
    { field: "id", headerName: "ID", flex: 0.2 },
    {
        field: "firstName",
        headerName: "First Name",
        flex: 0.4,
        cellClassName: "name-column--cell",
    },
    {
        field: "lastName",
        headerName: "Last Name",
        flex: 0.4,
        cellClassName: "name-column--cell",
    },
    {
        field: "gender",
        valueGetter: (fluentParticipant) => fluentParticipant.row.gender.gender,
        headerName: "Gender",
        flex: 0.3,
        cellClassName: "name-column--cell",
    },
    {
        field: "age",
        headerName: "Age",
        flex: 0.2,
        cellClassName: "name-column--cell",
    },
    {
        field: "occupationName",
        valueGetter: (fluentParticipant) => fluentParticipant.row.occupation.occupationName,
        headerName: "Occupation",
        flex: 0.4,
        cellClassName: "name-column--cell",
    },
    {
      field: "role",
    valueGetter: (fluentParticipant) => {
        let result = [];
        if (fluentParticipant.row.members != null) {
          fluentParticipant.row.members.map((members, index) => (
                result = members.role
            ))
        }
        return result;
      },
      headerName: "Role",
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "Move Beginner",
      Header: "Move Beginner",
      flex: 0.25,
      renderCell: ({ row: { id } }) => {
        return (
          user && user.members[0].id === 1 && (
          <Link to={`/editBeginnerParticipant/${id}`}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(event) => {
                handleClick(event);
              }}
            >
              Beginner
            </Button>
          </Link>
          )
        );
      }
  },
  {
    field: "Move Intermediate",
    Header: "Move Intermediate",
    flex: 0.4,
    renderCell: ({ row: { id } }) => {
      return (
        user && user.members[0].id === 1 && (
        <Link to={`/editBeginnerParticipant/${id}`}>
          <Button
            variant="contained"
            color="secondary"
            onClick={(event) => {
              handleClick(event);
            }}
          >
            Intermediate
          </Button>
        </Link>
        )
      );
    }
  }
  ];

  return (
    <Box m="20px" sx={{
      zoom: isMobile ? '60%' : '100%'
    }}>
      <Header
        title="Upper Intermediate Participant"
        subtitle="List of Upper Intermediate Participant"
      />

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={fluentParticipant}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default UpperIntermediateParticipant;
