import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "../features/authSlice";

const Role = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(user && user.members[0].id !== 1){
      navigate("/");
    }
  }, [user, navigate]);


  // const handleClick = (cellValues) => {
  //   console.log(cellValues.row);
  // };

  const [roles, setRole] = useState([]);

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    const response = await api.get(`/roles`);
    setRole(response.data);
  };

  // const Delete = async (id) => {
  //   await api
  //         .delete(`/roles/${id}`)
  //         .then(response => {
  //           console.log(response);
  //           });
  // }

  // const deleteRole = (id) => {
  //   try {
  //     Swal.fire({
  //       title: 'Are you sure?',
  //       text: "Do you want to delete this role?",
  //       icon: 'warning',
  //       showCancelButton: true,
  //       confirmButtonColor: '#f29727',
  //       cancelButtonColor: '#48ca40',
  //       confirmButtonText: 'Yes, delete it!'
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
            
  //           Delete(id);
  //           Swal.fire({  
  //             title: 'Success',  
  //             icon: 'success',  
  //             text: 'Role has been deleted.',
  //             timer: 3000
  //         })
  //           .then((result) => {
  //             if (result) {
  //             navigate(0);
  //             }
  //           })
  //         }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const columns = [ 
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 0.6,
      cellClassName: "name-column--cell",
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      flex: 0.6,
      cellClassName: "name-column--cell",
    }
    // {
    //     field: "Edit",
    //     Header: "Edit",
    //     flex: 0.3,
    //     renderCell: ({ row: { id } }) => {
    //       return (
    //         <Link to={`/editRole/${id}`}>
    //           <Button
    //             variant="contained"
    //             color="warning"
    //             onClick={(event) => {
    //               handleClick(event);
    //             }}
    //           >
    //             <EditIcon />
    //           </Button>
    //         </Link>
    //       );
    //     }
    // }
    // {
    //     field: "Delete",
    //     Header: "Delete",
    //     flex: 0.5,
    //     renderCell: ({ row: { id } }) => {
    //       return (
    //         <Button
    //           variant="contained"
    //           color="error"
    //           onClick={() => {
            
    //             deleteRole(id);
    //             // console.log(id);
    //           }}
    //         >
    //           <DeleteIcon />
    //         </Button>
    //       );
    //     }
    // }
  ];

  return (
    <Box m="20px">
      <Header
        title="Role"
        subtitle="List of Role User"
      />
      <Button 
      variant="contained" 
      color="success" 
      href="./addRole"
      >
        <AddIcon />Add Data</Button>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={roles}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Role;
