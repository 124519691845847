import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/configApi.js";
import { Box } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/Header.jsx";
import Swal from "sweetalert2";

const AddIntermediateSchedule = () => {
  const navigate = useNavigate();

  const [hostId, setHostId] = useState("");
  const [date, setDate] = useState("");
  const [topic, setTopic] = useState("");

  const [hostList, setHostList] = useState([]);

  useEffect(() => {
    getHostList();
  }, []);

  const getHostList = async () => {
    const responseHostList = await api.get(`/intermediateHost/7`);
    setHostList(responseHostList.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const forumScheduleData = {
      memberId: hostId,
      date: date,
      topic: topic,
    };

    try {
      await api
        .post(`/intermediateSchedule/`, forumScheduleData)
        .then((response) => {
          console.log(response);
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "New intermediate schedule has been saved.",
          });
          navigate("/intermediateSchedule");
        });
    } catch (error) {
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
      } else if (error.request) {
        console.log("network error");
      } else {
        console.log(error);
      }
    }
  };

  return (
    <Box m="20px">
      <div
        style={{
          bottom: 0,
          left: 0,
          top: 0,
          right: 0,
          zIndex: 0,
          overflow: "hidden",
        }}
      >
        <Header
          title="CREATE INTERMEDIATE SCHEDULE"
          subtitle="Create a New Intermediate Schedule"
        />

        <form className="container" onSubmit={handleSubmit}>
          <div className="text-light">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 p-3">
                  <div className="form-group p-3">
                    <label>
                      Host <span className="errmsg">*</span>
                    </label>
                    <select
                      value={hostId}
                      onChange={(e) => setHostId(e.target.value)}
                      className="form-control"
                    >
                      <option>Choose Host</option>
                      {hostList.map((host, index) => (
                        <option key={host.id} value={host.id}>
                          {host.firstName}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group p-3">
                    <label>
                      Date <span className="errmsg">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group p-3">
                    <label>
                      Topic <span className="errmsg">*</span>
                    </label>
                    <input
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      className="form-control"
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer p-3">
              <button type="submit" className="btn btn-success">
                Create new schedule
              </button>{" "}
              &nbsp; &nbsp;
              <Link to={"/intermediateSchedule"} className="btn btn-warning">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </Box>
  );
};

export default AddIntermediateSchedule;
