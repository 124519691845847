import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from "../features/authSlice";

const UserActivity = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
      dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(user && user.members[0].id !== 1){
      navigate("/");
    }
  }, [user, navigate]);

  const [userActivity, setUserActivity] = useState([]);

  useEffect(() => {
    getUserActivity();
  }, []);

  const getUserActivity = async () => {
    const response = await api.get(`/userActivity`);
    setUserActivity(response.data);
  };

  const columns = [ 
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "memberId",
      headerName: "Member ID",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
        field: "firstName",
        headerName: "First Name",
        valueGetter: (userActivity) => userActivity.row.member.firstName,
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "lastName",
        headerName: "Last Name",
        valueGetter: (userActivity) => userActivity.row.member.lastName,
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "email",
        headerName: "Email",
        valueGetter: (userActivity) => userActivity.row.member.email,
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "activity",
        headerName: "Activity User",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
      field: "createdAt",
      headerName: "Activity Date",
      flex: 0.6,
      cellClassName: "name-column--cell",
    }
    // {
    //     field: "Edit",
    //     Header: "Edit",
    //     flex: 0.3,
    //     renderCell: ({ row: { id } }) => {
    //       return (
    //         <Link to={`/editRole/${id}`}>
    //           <Button
    //             variant="contained"
    //             color="warning"
    //             onClick={(event) => {
    //               handleClick(event);
    //             }}
    //           >
    //             <EditIcon />
    //           </Button>
    //         </Link>
    //       );
    //     }
    // }
    // {
    //     field: "Delete",
    //     Header: "Delete",
    //     flex: 0.5,
    //     renderCell: ({ row: { id } }) => {
    //       return (
    //         <Button
    //           variant="contained"
    //           color="error"
    //           onClick={() => {
            
    //             deleteRole(id);
    //             // console.log(id);
    //           }}
    //         >
    //           <DeleteIcon />
    //         </Button>
    //       );
    //     }
    // }
  ];

  return (
    <Box m="20px">
      <Header
        title="User Activities"
        subtitle="List of User Activity Histories"
      />

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={userActivity}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default UserActivity;
