import Header from "../../components/Header";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Swal from "sweetalert2"; 

const EditTeam = () => {
  const navigate = useNavigate();
  const [memberId, setMemberId] = useState('');
  const [position, setPosition] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [file, setFile] = useState('');
  const [preview, setPreview] = useState('');
  
  const loadImage = (e) => {
    const profileImage = e.target.files[0];
    setFile(profileImage);
    setPreview(URL.createObjectURL(profileImage));
  };

  const {id} = useParams();
  const [members, setMembers] = useState([]);
  
  useEffect(()=>{
    const getTeamById = async () => {
      const response = await api.get(`/teams/${id}`);
      setMemberId(response.data.memberId);
      setFile(response.data.profileImage);
      setPreview(response.data.url);
      setFirstName(response.data.member.firstName);
      setLastName(response.data.member.lastName);
      setPosition(response.data.position);
  
    };
    getTeamById();
  }, [id]);

  useEffect(() => {
    getMembers();
  }, []);

  
  const handleSubmit = async(e) => {
    e.preventDefault();
    console.log(file);
    console.log(memberId);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("memberId", memberId);
    formData.append("positon", position);
    console.log(formData);
    
        try {
            await api
             .patch(`/teams/${id}`, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
             .then((response) => {
               console.log(response);
               Swal.fire({  
                  title: 'Success',  
                  icon: 'success',  
                  text: 'Team members has been updated.',  
                }); 
               navigate('/team');
             });
             
         } catch (error) {
             if (error.response) {
                 console.log(error.response);
                 console.log("server responded");
             } else if (error.request) {
               console.log("network error");
             } else {
               console.log(error);
             }
         }    
  
  };

  const getMembers = async () => {
    const responseMembers = await api.get(`/members`);
    setMembers(responseMembers.data);
  }

  return (
    <Box m="20px">
    <div style={{ bottom:0, left:0, top:0, right:0, zIndex: 0, overflow: 'hidden'}}>
      <Header title="Edit Sefeo Team" subtitle="Edit Sefeo Team" />

          <form className="container" onSubmit={handleSubmit}>
              <div className="text-light">
                  <div className="card-body">
                  <div className="row">
                      <div className="col-lg-4 p-3">
                          <div className="form-group">
                            <h3>{position}</h3>
                          </div>
                      </div>
                  </div>
                      <div className="row">
                        <div className="col-lg-4 p-3">
                              <div className="form-group">
                                  <label>Choose Member <span className="errmsg">*</span></label>
                                  <select value={memberId} onChange={e => setMemberId(e.target.value)} className="form-control">
                                      <option key={memberId} selected disabled>{firstName + ' ' + lastName}</option>
                                      <option disabled>============================================</option>
                                    {members.map((members, index) => (
                                      <option key={members.id} value={members.id}>{members.firstName + ' ' + members.lastName}</option>
                                      ))
                                    }
                                  </select>
                              </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-4 p-3">
                            <div className="form-group">
                                <label htmlFor="formFile" className="form-label">Change Profile Image</label>
                                <input className="form-control" type="file" id="formFile" onChange={loadImage} />
                            </div>
                        </div>
                    </div>
                    {preview ? (
                    <div className="row">
                      <div className="col-lg-6 p-3">
                        <div className="form-group">
                          <figure className="figure">
                            <img src={preview} className="figure-img img-fluid w-25 h-25 rounded" alt={preview} />
                          </figure>
                      </div>
                    </div>
                  </div>
                  ) : ("")}

                  </div>
                  <div className="card-footer">
                      <button type="submit" className="btn btn-success">Update Team</button> &nbsp; &nbsp;
                      <Link to={'/team'} className="btn btn-secondary">Back</Link>
                  </div>
              </div>
          </form>
   </div>
</Box>
  );
};

export default EditTeam;
