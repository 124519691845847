import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, {useState, useEffect} from "react";
import api from '../../api/configApi.js';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Swal from "sweetalert2"; 

const IntermediateHost = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  // const handleClick = (cellValues) => {
  //   console.log(cellValues.row);
  // };

  const { user } = useSelector((state) => state.auth);

  const [intermediateHost, setIntermediateHost] = useState([]);

  useEffect(() => {
    getIntermediateHost();
  }, []);

  const getIntermediateHost = async () => {
    const response = await api.get(`/memberByRoleId/7`);
    setIntermediateHost(response.data);
  };

  const Delete = async (id) => {
    await api
          .delete(`/host/${id}`)
          .then(response => {
            console.log(response);
            });
  }

  const deleteHost = (id) => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to fire this host?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f29727',
        cancelButtonColor: '#48ca40',
        confirmButtonText: 'Yes, fire it!'
      })
      .then((result) => {
        if (result.isConfirmed) {
            
            Delete(id);
            Swal.fire({  
              title: 'Success',  
              icon: 'success',  
              text: 'Host has been fired.',
              timer: 3000
          })
            .then((result) => {
              if (result) {
              navigate(0);
              }
            })
          }
      });
    } catch (error) {
      console.log(error);
    }
  };


  const columns = [ 
    { field: "id", headerName: "ID", flex: 0.5 },
    {
        field: "firstName",
        headerName: "First Name",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "lastName",
        headerName: "Last Name",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "gender",
        valueGetter: (intermediateHost) => intermediateHost.row.gender.gender,
        headerName: "Gender",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "age",
        headerName: "Age",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
        field: "occupationName",
        valueGetter: (intermediateHost) => intermediateHost.row.occupation.occupationName,
        headerName: "Occupation",
        flex: 0.5,
        cellClassName: "name-column--cell",
    },
    {
      field: "role",
    valueGetter: (intermediateHost) => {
        let result = [];
        if (intermediateHost.row.members != null) {
          intermediateHost.row.members.map((members, index) => (
                result = members.role
            ))
        }
        return result;
      },
      headerName: "Role",
      flex: 0.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "Delete",
      Header: "Delete",
      flex: 0.5,
      renderCell: ({ row: { id } }) => {
        return (
          user && user.members[0].id === 1 && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
          
              deleteHost(id);
            }}
          >
            <DeleteIcon />
          </Button>
          )
        );
      }
  }
  ];

  return (
    <Box m="20px">
      <Header
        title="Intermediate Host"
        subtitle="List of Intermediate Host"
      />
      { user && user.members[0].id === 1 && (
      <Button variant="contained" color="success" href="./addIntermediateHost"><AddIcon />Add Host</Button>
      )}
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={intermediateHost}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default IntermediateHost;
