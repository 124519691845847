import { Box, Button, TextField } from "@mui/material";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import React, {useEffect, useState} from "react";
import api from '../../api/configApi.js';
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2"; 

const EditOccupation = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(()=>{
    const getOccupationById = async () => {
      const response = await api.get(`/occupations/${id}`);
      setData(response.data);
    };
    
    getOccupationById();
  }, [id]);

  const [data, setData] = useState({
    occupationName: ""
  });

  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userData = {
      occupationName: data.occupationName
    };
    api
      .patch(`/occupations/${id}`, userData)
      .then((response) => {
        console.log(response);
            Swal.fire({  
      title: 'Success',  
      icon: 'success',  
      text: 'Occupation has been updated.',  
    }); 
        navigate("/occupation");
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  return (
    <Box m="20px">
      <Header title="Edit Occupation" subtitle="Update a Occupation" />
      <Formik>
      {({
          handleBlur,
        }) => (
        <div>
          <form onSubmit={handleSubmit}>
            <hr/>
            <br/>
          <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                placeholder="Input New Occupation..."
                type="text"
                onBlur={handleBlur}
                onChange={handleChange}
                value={data.occupationName}
                name="occupationName"
                sx={{ gridColumn: "span 2" }}
                required
              />
            </Box>
            <Box display="flex" justifyContent="first" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
                Update Occupation
              </Button>
            </Box>
          </form>
        </div>
        )}
      </Formik>
    </Box>
  );
};

export default EditOccupation;
