import { Box, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import api from "../../api/configApi.js";
import Button from "@mui/material/Button";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

const IntermediateSchedule = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const handleClick = (cellValues) => {
    console.log(cellValues.row);
  };

  const { user } = useSelector((state) => state.auth);

  const [forumSchedule, setForumSchedule] = useState([]);

  useEffect(() => {
    getForumSchedule();
  }, []);

  const getForumSchedule = async () => {
    const response = await api.get(`/intermediateSchedule`);
    setForumSchedule(response.data);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.2 },
    {
      field: "firstnName",
      valueGetter: (forumSchedule) => forumSchedule.row.member.firstName,
      headerName: "Host Name",
      flex: 0.3,
      cellClassName: "name-column--cell",
    },
    {
      field: "date",
      headerName: "Schedule",
      valueFormatter: (params) => {
        const formattedDate = moment(params.value).format(
          "DD MMMM YYYY, HH:mm:ss"
        );
        return formattedDate;
      },
      flex: 0.4,
      cellClassName: "name-column--cell",
    },
    {
      field: "topic",
      headerName: "Topic",
      flex: 0.2,
      cellClassName: "name-column--cell",
    },
    {
      field: "Join Forum",
      Header: "Join Forum",
      flex: 0.2,
      renderCell: ({ row: { id } }) => {
        return (
          user &&
          user.members[0].id === 5 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {

              }}
            >
              <ForumOutlinedIcon />
            </Button>
          )
        );
      },
    },
  ];

  return (
    <Box
      m="20px"
      sx={{
        zoom: isMobile ? "60%" : "100%",
      }}
    >
      <Header
        title="Intermediate Schedule"
        subtitle="List of Intermediate Schedule"
      />

      {user && user.members[0].id === 1 && (
        <Button
          variant="contained"
          color="success"
          href="./addIntermediateSchedule"
        >
          <AddIcon />
          Add Data
        </Button>
      )}

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.greenAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.greenAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={forumSchedule}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default IntermediateSchedule;
